<script setup lang="ts">
  import ChapterSummaryDefault from '../../Partials/ChapstersSummary/ChapterSummaryDefault.vue'
  import type { LeadParagraphData } from '../../../types/widgets/composites/LeadParagraphTypes'
  import type { ChapterSummaryData } from '../../../types/widgets/composites/ChapterSummaryTypes'

  const { data } = defineProps<{
    data: ChapterSummaryData
  }>()

  const componentMap: ComponentMap = {
    default: ChapterSummaryDefault,
  }

  const chapters = computed((): LeadParagraphData[] => {
    return useContentStore()
      ?.content?.contents?.data.map(widget =>
        widget.type === 'lead_paragraph' && widget.display_hint === 'chapter'
          ? widget
          : null
      )
      .filter(widget => !!widget)
  })

  const selectedComponent = computed(
    () => componentMap[data?.display_hint || 'default']
  )
</script>

<template>
  <section v-if="chapters?.length" class="center-content">
    <component :is="selectedComponent" :chapters="chapters" />
  </section>
</template>
